<template>
  <div class="page">
    <van-nav-bar
      :title="edit ? '修改抄表记录' : '新增抄表记录'"
      left-arrow
      @click-left="$router.go(-1)"
    />

    <div class="padding-10">
      <van-cell :title="info.name" />
      <van-cell
        v-if="info.billingType === 0"
        :title="useWaterType[info.useWaterType]"
        :value="$price(info.perPrice) + '元/吨'"
      />
      <van-cell
        v-if="info.billingType === 1 && info.perPrice"
        :title="useWaterType[info.useWaterType]"
        :value="$price(info.perPrice) + '元/期'"
      />
      <div class="block"></div>
      <div
        v-if="info.billingType === 0"
        class="reading border-bottom-1 bg-white"
      >
        <div class="reading-item reading-item-left">
          <div class="name">上次读数</div>
          <div class="val text-info">
            {{ recordInfo.dosage || info.lastDosage }}
          </div>
        </div>
        <div class="reading-item">
          <div>用量</div>
          <div class="margin-t-10 text-info" v-if="expectedNum <= 0">0</div>
          <div class="margin-t-10 text-success" v-else>
            +<span>{{ expectedNum }}</span>
          </div>
        </div>
        <div class="reading-item reading-item-right">
          <div class="name">本次读数</div>
          <div class="val">
            <input
              class="ipt"
              v-model="degrees"
              ref="aaa"
              autofocus
              type="number"
              @input="ipt"
            />
          </div>
        </div>
      </div>
      <div v-if="info.billingType === 0" class="block"></div>
      <van-field
        v-if="info.billingType === 1"
        class="price"
        v-model="noticeSheetId"
        label="通知单编号"
        input-align="center"
        placeholder="输入通知单编号"
        :formatter="formatter"
      >
      </van-field>
      <div v-if="info.billingType === 1" class="block"></div>

      <van-field
        class="price"
        label="应收(元)"
        v-model="expectedPrice"
        type="number"
        input-align="center"
        placeholder="请输入金额(元)"
      >
        <template #button>
          <van-button size="small" type="primary" @click="round"
            >取整</van-button
          >
        </template>
      </van-field>
      <div class="font-12 text-info padding-t-10">
        注：应收金额为最后订单金额，即收费金额。若后续为人工收费，建议取整（四舍五入）。
      </div>

      <div class="block"></div>

      <!-- 总价 -->
      <div
        v-if="info.billingType === 0"
        class="padding-16 d-flex col-center row-between bg-white bottom"
      >
        <div>
          <span class="font-14">价格:</span>
          <span class="margin-l-5 text-error font-20">{{
            expectedPrice || 0
          }}</span>
          <span class="font-12 margin-l-5">元</span>
        </div>
        <van-button
          round
          type="primary"
          style="width: 100px; font-size: 16px"
          :disabled="disabled"
          @click="onSubmit"
        >
          新 增
        </van-button>
      </div>
      <div
        v-else
        class="padding-16 d-flex col-center row-between bg-white bottom"
      >
        <div>
          <span class="font-14">缴费金额:</span>
          <span class="margin-l-5 text-error font-20">
            {{ expectedPrice || 0 }}
          </span>

          <span class="font-12 margin-l-5">元</span>
        </div>
        <van-button
          round
          type="primary"
          style="width: 100px; font-size: 16px"
          :disabled="
            !noticeSheetId || expectedPrice < 0 || expectedPrice === ''
          "
          @click="onSubmit"
        >
          新 增
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
//水表有两种计费方式，如果是使用量就填水的吨数，如果是固定就填通知单编号
import { useWaterType } from "@/config/const.js";
import { getRecordList } from "@/apis/water-record.js";
import { getWaterUnit } from "@/apis/water-unit.js";
import { addRecord } from "@/apis/water-record.js";
import { Toast } from "vant";
export default {
  name: "AddRecord",
  props: {},
  data() {
    return {
      noticeSheetPrice: 0,
      useWaterType, //用水性质说明
      id: "", //水表id
      degrees: "", //本次抄表吨数
      noticeSheetId: "",
      info: "", //水表信息
      recordInfo: {}, //记录信息
      edit: 0,
      expectedPrice: "",
      flag: false,
    };
  },
  computed: {
    disabled() {
      if (
        this.degrees &&
        this.degrees >= (this.recordInfo.dosage || this.info.lastDosage) &&
        this.expectedPrice !== "" &&
        this.expectedPrice >= 0
      ) {
        return false;
      } else {
        return true;
      }
    },
    expectedNum() {
      let num = this.$price(
        this.degrees || 0,
        this.recordInfo.dosage || this.info.lastDosage,
        "-"
      );
      return num;
    },
  },
  mounted() {
    //获取记录id
    this.id = this.$route.query.id;
    this.edit = this.$route.query.edit;
    let toast = this.$toast.loading({
      message: "加载中...",
      forbidClick: true,
      overlay: true,
    });
    getWaterUnit(this.id).then((res) => {
      if (res.succeeded) {
        this.info = res.data;
        this.noticeSheetPrice = this.$price(this.info.perPrice);
        // 获取光标
        if (this.info.billingType === 0) {
          this.$nextTick(() => {
            this.$refs.aaa.focus();
          });
        } else {
          this.expectedPrice = this.noticeSheetPrice;
        }
      }
      toast.clear();
    });
    // 获取上次记录
    getRecordList({
      WaterUnitId: this.id,
      MaxCount: 1,
      SkipCount: 0,
    }).then((res) => {
      if (res.succeeded) {
        if (res.data.items.length > 0) {
          this.recordInfo = res.data.items[0];
          if (this.edit) {
            this.noticeSheetId = this.recordInfo.noticeSheetId;
          }
        }
      }
    });
  },
  methods: {
    round() {
      this.expectedPrice = Math.round(this.expectedPrice);
    },
    ipt() {
      // let val = event.currentTarget.value;
      if (this.degrees) {
        let num1 = this.$price(
          this.degrees - 0,
          this.recordInfo.dosage || this.info.lastDosage,
          "-"
        );
        let num2 = this.$price(this.info.perPrice);
        let price = this.$price(num1, num2, "*");
        console.log("🚀 ~ price", price);
        if (price > 0) {
          this.expectedPrice = price;
        } else {
          this.expectedPrice = 0;
        }
      } else {
        this.expectedPrice = 0;
      }
    },
    formatter(val) {
      return val.replace(/[\u4E00-\u9FA5]/g, "");
    },
    onSubmit() {
      if (this.flag) return;
      let obj = {};
      if (this.expectedPrice < 0 || this.expectedPrice === "") {
        this.$toast("请输入正确的价格！");
        return;
      }
      if (this.info.billingType === 0) {
        if (!this.degrees) {
          this.$toast("抄表数不能为空");
          return;
        }
        obj = {
          waterUnitId: this.id,
          dosage: this.degrees,
          price: this.$price(this.expectedPrice || 0, 100, "*"),
        };
        let num = this.recordInfo.dosage || this.info.lastDosage;
        if (this.degrees < num) {
          this.$toast("不能比上一次抄表数值小");
          return;
        }
      } else {
        if (!this.noticeSheetId) {
          this.$toast("通知单编号不能为空");
          return;
        }
        obj = {
          waterUnitId: this.id,
          noticeSheetId: this.noticeSheetId.replace(/\s+/g, ""),
          price: this.$price(this.expectedPrice || 0, 100, "*"),
        };
      }

      let toast = this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        overlay: true,
        duration: 0,
      });
      this.flag = true;
      addRecord(obj)
        .then((res) => {
          if (res.succeeded) {
            if (this.info.billingType === 0) {
              if (res.data.paid) {
                this.$toast.success({
                  message: "操作成功！",
                  overlay: true,
                  duration: 1500,
                });
                setTimeout(() => {
                  this.$router.go(-1);
                }, 1500);
              } else {
                this.$router.replace({
                  path:
                    "/payment-method?id=" +
                    res.data.id +
                    "&price=" +
                    res.data.estimatedPrice,
                });
              }
            } else {
              Toast.setDefaultOptions({ overlay: true });
              Toast.success("操作成功！");
              setTimeout(() => {
                this.$router.go(-1);
              }, 1500);
            }
            toast.clear();

            setTimeout(() => {
              this.flag = false;
            }, 1500);
          }
        })
        .catch(() => {
          toast.clear();
          this.flag = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  height: 100vh;
  background-color: #f3f3f3;
}
.block {
  height: 10px;
}
.bg-white {
  background-color: #fff;
}

.border-bottom-1 {
  border-bottom: 1px solid #f5f5f5;
}
.reading {
  display: flex;
  padding: 15px;
  justify-content: space-between;

  .reading-item {
    text-align: center;
  }
  .name {
    margin-bottom: 10px;
  }
  .val {
    text-align: center;
  }
  .ipt {
    width: 80px;
    border: none;
    border-bottom: 1px solid #333;
    text-align: center;
    // background-color: #eee;
  }
}

.predictPrice {
  .name {
    text-align: center;
  }
  .val {
    text-align: center;
    font-size: 24px;
    margin-top: 10px;

    span {
      color: #666;
      font-size: 12px;
    }
  }
}

.van-button__text {
  font-weight: 700;
}

.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.price {
  ::v-deep .van-cell__title {
    color: #333;
    font-size: 16px;
  }
  ::v-deep .van-field__control {
    font-size: 16px;
    border-bottom: 1px solid #333;
    width: 120px;
  }
  ::v-deep .van-field__body {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
